import React, {useState, useEffect, useRef} from "react";
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby";
import styled from 'styled-components';
import {Content} from "../UsableComponents/Content";
import {H1} from "../UsableComponents/H1";
import {H2} from "../UsableComponents/H2";
import {H3} from "../UsableComponents/H3";
import {Paragraph} from "../UsableComponents/Paragraph";
import {Button} from "../UsableComponents/Button";
import {Input} from "../UsableComponents/Input";
import {theme} from "../../utils/theme";
import logo from '../../images/logo.png';
import hosItWorksBgc from '../../images/how-to.png';
import manLogo from '../../images/grafika-1.png';
import womenLogo from '../../images/grafika-2.png';
import copy from '../../images/ikonka-copy.png';
import redAlert from '../../images/alert2.png';
import orangeAlert from '../../images/orangeAlert.png';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Container} from "../UsableComponents/Container";
import Dropdown from "react-bootstrap/Dropdown";
import {DropdownMenu} from "../UsableComponents/DropdownMenu";
import {DropdownToggle} from "../UsableComponents/DropdownToggle";
import {DropdownItem} from "../UsableComponents/DropdownItem";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import checkOTCUrl from "../../features/appHealthCheck/checkOTCUrl";
import checkRatesUrl from "../../features/appHealthCheck/checkRatesURl";

const StyledContent = styled(Content)`
    position: relative;
    
    ${theme.media.phoneSmall} {
        padding: 40px 0 30px;
    }
    
    ${theme.media.tabletSmall} {
        padding: 30px 0;
    }
    
    ${theme.media.tabletLarge} {
        padding: 50px 0;
    }
`;

const StyledH1 = styled(H1)`
    margin: 0 0 20px;
    
    ${theme.media.phoneSmall} {
        width: 90%;
    }
    
    ${theme.media.desktop} {
        width: 85%;
    }
`;

const KangaLogo = styled.img`
    position: absolute;
    top: 35px;
    
    ${theme.media.phoneSmall} {
        display: none;
    }
    
    ${theme.media.desktopLarge} {
        display: block;
        left: -180px;
    }
`;

const ManLogo = styled.img`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        display: none;
    }
    
    ${theme.media.desktopLarge} {
        width: 220px;
        display: block;
        top: 327px;
        left: -195px;
    }
`;

const WomenLogo = styled.img`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        display: none;
    }
    
    ${theme.media.desktopLarge} {
        width: 160px;
        right: -130px;
        top: 314px;
        display: block;
    }
`;

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: ${theme.fonts.main};
    margin: 20px 0 5px;
    align-items: center;
    
    ${theme.media.phoneSmall} {
        width: 90%;
    }
    
    ${theme.media.tabletLarge} {
        width: 75%;
    }
    
    ${theme.media.desktop} {
        width: 85%;
    }
`;

const NavigationContainerElement = styled.div`
    color: ${({active}) => active ? `${theme.colors.orange1}` : `${theme.colors.black}`};
    font-weight: ${({active}) => active ? '500' : '300'};
    
    :hover {
        color: ${theme.colors.orange1};
        cursor: pointer;
    }
    
    ${theme.media.phoneSmall} {
        font-size: 13px;
        text-align: center;
    }
    
    ${theme.media.tabletSmall} {
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        font-size: 16px;
    }
`;

const StyledLink = styled.a`
    color: ${theme.colors.black};
    text-decoration: none;
    font-weight: 300;
    
    :hover {
        color: ${theme.colors.orange1};
    }
    
    ${theme.media.phoneSmall} {
        font-size: 13px;
        text-align: center;
    }
    
    ${theme.media.tabletSmall} {
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        font-size: 16px;
    }
`;

const StyledUnderline = styled.div`
    background-color: ${theme.colors.orange1};
    height: 1px;
    
    ${theme.media.phoneSmall} {
        width: 90%;
    }
    
    ${theme.media.tabletLarge} {
        width: 75%;
    }
    
    ${theme.media.desktop} {
        width: 85%;
    }
`;

const StyledH2 = styled(H2)`
    text-transform: uppercase;
    color: ${theme.colors.orange1};
    margin: 30px 0 0;
    
    ${theme.media.phoneSmall} {
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        font-size: 16px;
    }
`;

const Text = styled(Paragraph)`
    color: ${theme.colors.orange1};
    margin-top: 5px;
    font-weight: 400;
`;

const CryptoesLogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    
    ${theme.media.phoneSmall} {
        width: 90%;
    }
    
    ${theme.media.phoneLarge} {
        width: 80%;
    }
    
    ${theme.media.tabletSmall} {
        width: 60%;
    }
`;

const CryptoesLogoSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    :hover {
        cursor: pointer;
    }
`;

const CryptoLogo = styled.img`
    pointer-events: none;
    
    ${theme.media.phoneSmall} {
        height: 50px;
    }
    
    ${theme.media.tabletLarge} {
        height: auto;
    }
`;

const CryptoName = styled.span`
    text-align: center;
    font-family: ${theme.fonts.main};
    margin-top: 5px;
    font-weight: 300;
    pointer-events: none;
    color: ${({active}) => active ? `${theme.colors.orange1}` : `${theme.colors.grey3}`};
    
    ${theme.media.phoneSmall} {
        font-size: 13px;
    }
    
    ${theme.media.tabletSmall} {
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        font-size: 16px;
    }
`;

const GenerateVoucherButton = styled(Button)`
    background-size: 200%;
    background-image: linear-gradient(to left, ${theme.colors.orange2}, ${theme.colors.orange3}, ${theme.colors.orange2});
    margin: 30px 0;
    transition: .3s;
    
    :disabled {
        background-image: linear-gradient(to left, ${theme.colors.grey1}, ${theme.colors.grey1}, ${theme.colors.grey1});
        cursor: auto;
    }
    
    :hover {
        background-position: right;
    }
    
    ${theme.media.tabletSmall} {
        width: 40%;
    }
    
    ${theme.media.tabletLarge} {
        width: 25%;
    }
`;

const RatesButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    
    ${theme.media.phoneSmall} {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    ${theme.media.tabletLarge} {
        width: 75%;
    }
    
    ${theme.media.desktop} {
        flex-direction: row;
        width: 85%;
    }
`;

const RatesButton = styled.button`
    border: ${({active}) => active ? `1px solid ${theme.colors.transparent}` : `1px solid ${theme.colors.orange1}`};
    font-weight: 300;
    color: ${({active}) => active ? `${theme.colors.white}` : `${theme.colors.orange1}`};
    transition: 0.3s;
    font-family: ${theme.fonts.main};
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    background-size: 200%;
    background-image: ${({active}) => active ? `linear-gradient(to left, ${theme.colors.orange2}, ${theme.colors.orange3}, ${theme.colors.orange2});` : `linear-gradient(to left, ${theme.colors.white}, ${theme.colors.white}, ${theme.colors.white});`}
    
    :hover {
        cursor: pointer;
        color: ${({active}) => active ? `${theme.colors.white}` : `${theme.colors.black}`};
    }
    
    ${theme.media.phoneSmall} {
        font-size: 14px;
        width: 75%;
        padding: 8px 0;
        margin-bottom: 15px;
    }
    
    ${theme.media.phoneLarge} {
        width: 65%;
    }
    
    ${theme.media.tabletSmall} {
        width: 50%;
    }
    
    ${theme.media.tabletLarge} {
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        width: 30%;
        font-size: 16px;
        padding: 15px 0;
    }
`;

const CryptoesContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: ${theme.fonts.main};
    color: ${theme.colors.orange1};
    font-weight: 500;
    margin-top: 30px;
    
    ${theme.media.phoneSmall} {
        width: 90%;
        font-size: 12px;
    }
    
    ${theme.media.phoneLarge} {
        font-size: 13px;
        width: 84%;
    }
    
    ${theme.media.tabletSmall} {
        width: 75%;
    }
    
    ${theme.media.tabletLarge} {
        width: 65%;
        font-size: 14px;
    }
`;

const CryptoesCourseContainer = styled(CryptoesContainer)`
    color: ${theme.colors.black};
    margin: 5px 0 30px;
`;

const ContainerElement = styled.div`
    width: 22%;
    text-align: center;
`;

const InfoMessage = styled(Paragraph)`
    ${theme.media.phoneSmall} {
        width: 90%;
        text-align: center;
        margin: 10px 0 0;
    }
    
    ${theme.media.tabletSmall} {
        width: 80%;
    }
    
    ${theme.media.tabletLarge} {
        margin: 20px 0 0;
        width: 75%;
    }
    
    ${theme.media.desktop} {
        width: 85%;
    }
`;

const LoginMessage = styled(Paragraph)`
    color: ${theme.colors.orange1};
    margin: 5px 0 30px;
    font-weight: 400;
    
    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const CustomH2 = styled(StyledH2)`
    text-transform: none;

    ${theme.media.phoneSmall}{
        font-size: 15px;
    }
    
    ${theme.media.phoneLarge}{
        font-size: 16px;
    }
    
    ${theme.media.tabletSmall} {
        font-size: 20px;
    }
    
    ${theme.media.desktop} {
        font-size: 28px;
    }
`;

const HowItWorksMainContainer = styled.div`
    position: relative;
    
    ${theme.media.phoneSmall} {
        width: 80%;
    }
    
    ${theme.media.tabletLarge} {
        width: 75%;
    }
    
    ${theme.media.desktop} {
        width: 85%;
    }
`;

const HowItWorksSubContainer = styled.div`
    width: 100%;
    height: 990px;
    position: absolute;
    top: -40px;
    left: 0;
    background-image: url(${hosItWorksBgc});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    ${theme.media.phoneSmall} {
        display: none;
    }
    
    ${theme.media.desktop} {
        display: block;
    }
`;

const HowItWorksChooseCryptoContainer = styled.div`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        width: 100%;
        top: 0;
        left: 0;
    }
    
    ${theme.media.desktop} {
        width: 70%;
        top: 25px;
        left: 40px;
    }
`;

const HowItWorksGenerateAddressContainer = styled.div`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        width: 100%;
        top: 160px;
        left: 0;
    }
    
    ${theme.media.tabletSmall} {
        top: 130px;
    }
    
    ${theme.media.tabletLarge} {
        top: 110px;
    }
    
    ${theme.media.desktop} {
        width: 80%;
        top: 199px;
        left: 125px;
    }
`;

const HowItWorksPaymentDetailsContainer = styled.div`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        width: 100%;
        top: 245px;
        left: 0;
    }
    
    ${theme.media.phoneLarge} {
        top: 255px;
    }
    
    ${theme.media.tabletSmall} {
        top: 215px;
    }
    
    ${theme.media.tabletLarge} {
        top: 190px;
    }
    
    ${theme.media.desktop} {
        width: 81%;
        top: 318px;
        left: 35px;
    }
`;

const HowItWorksPayInContainer = styled.div`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        width: 100%;
        top: 360px;
        left: 0;
    }
    
     ${theme.media.phoneLarge} {
        top: 375px;
    }
    
    ${theme.media.tabletSmall} {
        top: 320px;
    }
    
    ${theme.media.tabletLarge} {
        top: 280px;
    }
    
    ${theme.media.desktop} {
        width: 80%;
        top: 468px;
        left: 145px;
    }
`;

const HowItWorksExchangeOfficeContainer = styled.div`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        width: 100%;
        top: 450px;
        left: 0;
    }
    
    ${theme.media.tabletSmall} {
        top: 410px;
    }
    
    ${theme.media.tabletLarge} {
        top: 360px;
    }
    
    ${theme.media.desktop} {
        width: 60%;
        top: 593px;
        left: 40px;
    }
`;

const HowItWorksTransactionContainer = styled.div`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        width: 100%;
        top: 540px;
        left: 0;
    }
    
    ${theme.media.phoneLarge} {
        top: 545px;
    }
    
    ${theme.media.tabletSmall} {
        top: 500px;
    }
    
    ${theme.media.tabletLarge} {
        top: 450px;
    }
    
    ${theme.media.desktop} {
        width: 58%;
        top: 708px;
        left: 162px;
    }
`;

const HowItWorksCashContainer = styled.div`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        width: 100%;
        top: 670px;
        left: 0;
    }
    
    ${theme.media.phoneLarge} {
        top: 665px;
    }
    
    ${theme.media.tabletSmall} {
        top: 620px;
    }
    
    ${theme.media.tabletLarge} {
        top: 560px;
    }
    
    ${theme.media.desktop} {
        width: 55%;
        top: 853px;
        left: 112px;
    }
`;

const StyledH3 = styled(H3)`
    color: ${theme.colors.orange1};
    margin: 30px 0 0;
    
    ${theme.media.desktop} {
        font-size: 16px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    margin: 5px 0 0;
    
    ${theme.media.desktop} {
        font-size: 13px;
    }
`;

const HowItWorksLink = styled(StyledLink)`
    color: ${theme.colors.orange1};
    font-size: 14px;
    font-weight: 400;
    
    :hover {
        text-decoration: underline;
    }
    
    ${theme.media.phoneSmall} {
        font-size: 12px;
    }
    
    ${theme.media.phoneLarge} {
        font-size: 13px;
    }
    
    ${theme.media.desktop} {
        font-size: 14px;
    }
`;

const LastParagraph = styled(StyledParagraph)`
    ${theme.media.phoneSmall} {
        margin-bottom: 80px;
    }
    
    ${theme.media.tabletLarge} {
        margin-bottom: 100px;
    }
`;

const CheckVoucherTitle = styled(H2)`
    text-transform: none;
    color: ${theme.colors.orange1};
    margin: 30px 0 0;
    text-align: center;
    
    ${theme.media.phoneSmall} {
        width: 90%;
    }
    
    ${theme.media.tabletLarge} {
        width: 75%;
    }
`;

const CheckVoucherContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    
    ${theme.media.phoneSmall} {
        width: 75%;
        flex-direction: column;
        align-items: center;
    }
    
    ${theme.media.tabletSmall} {
        flex-direction: row;
        width: 65%;
    }
    
    ${theme.media.tabletLarge} {
        width: 48%;
    }
    
    ${theme.media.desktop} {
        width: 40%;
    }
`;

const CheckVoucherButton = styled(Button)`
    margin: 0 0 30px;
    
    ${theme.media.phoneSmall} {
        width: 80%;
    }
    
    ${theme.media.phoneLarge} {
        width: 65%;
    }
    
    ${theme.media.tabletSmall} {
        width: 46%;
    }
    
    ${theme.media.tabletLarge} {
        width: 35%;
    }
`;

const StyledInfoMessage = styled(InfoMessage)`
    margin: 5px 0 0;
`;

const LastInfoMessage = styled(InfoMessage)`
    margin: 5px 0 30px;
`;

const StyledSpan = styled.span`
    font-family: ${theme.fonts.main};
    font-size: 14px;
    color: ${theme.colors.orange1};
    font-weight: 400;
`;

const QrAddressContainerPl = styled(Container)`
    position: relative;
    align-items: center;
    margin: 30px 0 15px;
    
    :after {
        content: url(${copy});
        position: absolute;
        pointer-events: none;
        display: ${({qrAddress}) => qrAddress ? "none" : "block"}
    }
    
    :before {
        content: "Skopiowano!";
        color: ${theme.colors.orange1};
        font-family: ${theme.fonts.main};
        position: absolute;
        pointer-events: none;
        display: ${({qrAddress}) => qrAddress ? "block" : "none"};
    }
    
    ${theme.media.phoneSmall}{
        width: 250px;
        :after {
            right: 3px;
            top: 10px;
        }
        
        :before {
            font-size: 9px;
            right: 3px;
            top: 25px;
        }
    }  
    
    ${theme.media.phoneLarge}{
        width: 350px;
        :after {
            right: 23px;
            top: 10px;
        }
    
        :before {
            font-size: 11px;
            right: 23px;
            top: 25px;
        }
    }
    
    ${theme.media.tabletSmall}{
        :after {
            right: -15px;
            top: 10px;
        }
    
        :before {
            font-size: 13px;
            right: -20px;
            top: 15px;
        }
    }
    
    ${theme.media.tabletLarge}{
        :after {
            right: -25px;
        }
    
        :before {
            right: -25px;
        }
    }
`;

const QrAddressContainerEn = styled(QrAddressContainerPl)`
    :before {
        content: "Copied!";
    }
`;

const QrAddressContainerUk = styled(QrAddressContainerPl)`
    :before {
        content: "Скопійовано!";
    }
`;

const StyledQrAddressContainerPl = styled(QrAddressContainerPl)`
    margin-top: 0;
`;

const StyledQrAddressContainerEn = styled(QrAddressContainerEn)`
    margin-top: 0;
`;

const StyledQrAddressContainerUk = styled(QrAddressContainerUk)`
    margin-top: 0;
`;

const QrAddressInput = styled(Input)`
    text-align: left;
    width: 420px;
    padding-left: 10px;
    height: 45px;
    
    :hover {
        cursor: pointer;
    }
    
    ${theme.media.phoneSmall} {
        width: 260px;
        font-size: 9px;
        padding-left: 5px;
        padding-bottom: 12px;
    }
    
    ${theme.media.phoneLarge} {
        width: 320px;
        font-size: 11px;
        padding-bottom: 14px;
    }
    
    ${theme.media.tabletSmall} {
        width: 400px;
        font-size: 13px;
        padding-bottom: 0;
    }
    
    ${theme.media.tabletLarge} {
        width: 420px;
    }
`;

const QrContainer = styled.div`
    text-align: center;
    margin: 30px 0;
    
    ${theme.media.phoneSmall}{
        width: 80%;
    }
    
    ${theme.media.desktop} {
        width: 36%;
    }
`;

const QrParagraph = styled(Paragraph)`
    margin: 10px 0;
    font-weight: 400;
`;

const GetVoucherButton = styled(Button)`
    margin: 20px 0 30px;
    
    ${theme.media.tabletSmall} {
        width: 40%;
    }
    
    ${theme.media.tabletLarge} {
        width: 25%;
    }
`;

const CustomH3 = styled(H3)`
    margin: 0;
    text-align: center;
    color: ${theme.colors.red}
`;

const ManageVoucherTitle = styled(StyledH3)`
    ${theme.media.phoneSmall} {
        font-size: 16px;
    }
    
    ${theme.media.desktop} {
        font-size: 18px;
    }
`;

const CustomSpan = styled(StyledSpan)`
    color: ${theme.colors.black};
`;

const CustomVoucherSpan = styled(StyledSpan)`
    color: ${theme.colors.red};
`;

const ErrorMessage = styled(Paragraph)`
    color: ${theme.colors.red};
    font-weight: 600;
    margin: 0 0 25px;
`;

const VoucherInfoTitle = styled(Paragraph)`
    margin: 25px 0 5px;
`;

const VoucherInfo = styled(Paragraph)`
    font-weight: 700;
    margin: 0;
`;

const StyledInput = styled(Input)`
    ${theme.media.phoneSmall} {
        margin-bottom: 15px;
    }
    
    ${theme.media.tabletSmall} {
        margin-bottom: 0;
    }
`;

const ParagraphInfo = styled(Paragraph)`
    margin: 30px 0 20px;
    
    ${theme.media.phoneSmall} {
        width: 90%;
        text-align: center;
    }
    
    ${theme.media.tabletSmall} {
        width: 80%;
    }
    
    ${theme.media.desktop} {
        width: 75%;
    }
`;

const StyledParagraphInfo = styled(ParagraphInfo)`
    margin: 0;
    text-align: center;
`;

const VoucherInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 0 0;
    
    ${theme.media.phoneSmall} {
        width: 90%;
    }
    
    ${theme.media.tabletSmall} {
        width: 80%;
    }
`;

const InputsContainerPl = styled.div`
    height: 45px;
    margin-bottom: 30px;
    width: 100%;
    position: relative;
    
    &.active {
        display: block;
    }
    
    &.nonActive {
        display: none;
    }
    
    :after {
        content: "Stan konta";
        color: ${theme.colors.black};
        font-family: ${theme.fonts.main};
        position: absolute;
        pointer-events: none;
    }
    
    ${theme.media.phoneSmall}{
        :after {
            font-size: 10px;
            top: 12px;
            left: 6px;
        }
    }

    ${theme.media.phoneLarge} {
        :after {
            font-size: 12px;
            top: 11px;
        }
    }

    ${theme.media.tabletLarge} {
        :after {
            font-size: 13px;
        }
    }

    ${theme.media.desktop} {
        :after {
            font-size: 14px;
            top: 15px;
            left: 10px;
        }
    }
`;

const InputsContainerEn = styled(InputsContainerPl)`
    :after {
        content: "Account balance";
    }
`;

const InputsContainerUk = styled(InputsContainerPl)`
    :after {
        content: "баланс";
    }
`;

const VoucherValueInput = styled(Input)`
    text-align: right;
    border-radius: 5px 0 0 5px;
    border-right: none;
    
    :hover {
        cursor: auto;
    }
    
    ${theme.media.phoneSmall} {
        font-size: 12px;
        height: 35px;
        width: 70%;
    }
    
    ${theme.media.phoneLarge} {
        font-size: 13px;
    }
    
    ${theme.media.tabletLarge} {
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        height: 45px;
        width: 75%;
    }
`;

const VoucherInfoButton = styled(Button)`
    border-radius: 0px 5px 5px 0;
    text-transform: uppercase;
    
    ${theme.media.phoneSmall} {
        height: 35px;
        font-size: 12px;
        padding: 0;
        width: 30%;
    }
    
    ${theme.media.phoneLarge} {
        font-size: 13px;
    }
    
    ${theme.media.tabletLarge} {
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        width: 25%;
        height: 45px;
    }
`;

const VoucherInfoButtonDisabled = styled(VoucherInfoButton)`
    background-image: none;
    background-color: ${theme.colors.grey2};
    
    :hover {
        cursor: auto;
    }
`;

const StyledCheckVoucherButton = styled(CheckVoucherButton)`
    margin: 0 0 30px;
`;

const ModalButtonsContainer = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
`;

const ConfirmButton = styled(CheckVoucherButton)`
    margin: 0;
    
    ${theme.media.phoneSmall} {
        width: 45%;
    }
`;

const CancelButton = styled(RatesButton)`
    margin: 0;
    padding: 11px 0;
    
    ${theme.media.phoneSmall} {
        width: 45%;
    }
`;

const StyledDropdown = styled(Dropdown)`
    position: absolute;

    :after {
        content: "";
        border: solid ${theme.colors.orange1};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        position: absolute;
        right: 10px;
        pointer-events: none;
    }
    
    ${theme.media.phoneSmall} {
        top: 10px;
        right: 15px;
        
        :after {
            top: 7px;
        }
    }
    
    ${theme.media.tabletSmall} {
        right: -35px;
    }
    
    ${theme.media.tabletLarge} {
        top: 20px;
        right: -10px;
        
        :after {
            top: 12px;
        }
    }
    
    ${theme.media.desktop} {
        top: 60px;
        right: -30px;
    }
`;

const ChooseNetworkContainer = styled.div`
    display: flex;
    
    ${theme.media.phoneSmall} {
        flex-direction: column;   
        align-items: center;
        margin: 10px 0 0; 
    }
    
    ${theme.media.desktop} {
        flex-direction: row;
        align-items: center;
        margin: 30px 0 20px;
    }
`;

const ChooseNetworkTitle = styled.p`
    font-family: ${theme.fonts.main};
    font-weight: 400;
    text-transform: uppercase;
    color: ${theme.colors.orange1};
    
    ${theme.media.phoneSmall} {
        margin: 0 0 10px 0;
        text-align: center;
    }
    
    ${theme.media.desktop} {
        margin: 0 20px 0 0;
        text-align: left;
    }
`;

const CustomDropdown = styled(Dropdown)`
    position: relative;
    
    :after {
        content: "";
        border: solid ${theme.colors.orange1};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        position: absolute;
        top: 12px;
        pointer-events: none;
    }
    
    ${theme.media.phoneSmall} {
        :after {
            top: 10px;
            right: 15px;
        }
    }
    
    ${theme.media.tabletLarge} {
        :after {
            top: 12px;
            right: 10px;
        }
    }
`;

const CustomDropdownMenu = styled(DropdownMenu)`
    text-transform: none;
    
    ${theme.media.phoneSmall} {
        width: 160px;
    }
    
    ${theme.media.tabletLarge} {
        width: 180px;
    }
`;

const CustomDropdownToggle = styled(DropdownToggle)`
    text-transform: none;
    color: ${theme.colors.grey2};
    
    ${theme.media.phoneSmall} {
        width: 160px;
        height: 30px;
    }
    
    ${theme.media.tabletLarge} {
        height: 35px;
        width: 180px;
    }
`;

const CustomDropdownItem = styled(DropdownItem)`
    color: ${theme.colors.grey2};
`;

const AlertContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 10px;
    
    ${theme.media.phoneSmall} {
        flex-direction: column;
    }
    
    ${theme.media.desktop} {
        flex-direction: row;
    }
`;

const GenerateAddressAlertContainer = styled(AlertContainer)`
    margin: 15px 0 0;
`;

const VoucherRecoveryContainer = styled(GenerateAddressAlertContainer)`
     ${theme.media.phoneSmall} {
        width: 90%;
    }
    
    ${theme.media.tabletSmall} {
        width: 75%;
    }
`;

const VoucherRecovery = styled(VoucherRecoveryContainer)`
    margin: 10px 0;
`;

const StyledAlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const AlertSubContainer = styled.div`
    display: flex;
    align-items: center;
    
    ${theme.media.phoneSmall} {
        flex-direction: column;
    }
    
    ${theme.media.desktop} {
        flex-direction: row;
    }
`;

const EmptyVoucherAlertContainer = styled(AlertSubContainer)`
    ${theme.media.phoneSmall} {
        margin: 0 auto 20px;
    }
    
    ${theme.media.desktop} {
        margin: 0 auto 14px;
    }
`;

const Alert = styled.img`
    ${theme.media.phoneSmall} {
        width: 25px;
    }
    
    ${theme.media.desktop} {
        width: 30px;
        margin-right: 20px;
    }
`;

const AlertText = styled(Paragraph)`
    font-weight: 600;
    
    ${theme.media.phoneSmall} {
        width: 90%;
        text-align: center;
    }
`;

const CustomAlertText = styled(Paragraph)`
    font-weight: 600;
    
    ${theme.media.phoneSmall} {
        margin: 20px 0 30px;
        text-align: center;
    }
    
    ${theme.media.desktop} {
        margin: 14px 0 30px;
    }
`;

const StyledAlertText = styled(Paragraph)`
    font-weight: 600;
    
    ${theme.media.phoneSmall} {
        margin: 12px 0 0;
    }
    
    ${theme.media.desktop} {
        margin: 0;
    }
`;

const AlertTextSpan = styled.span`
    font-size: 16px;
    color: ${theme.colors.orange1};
`;

const BlockchainAnchor = styled.a`
    font-family: ${theme.fonts.main};
    
    ${theme.media.phoneSmall} {
        font-size: 14px;
        width: 90%;
        margin: 2px 0 0;
        overflow-wrap: break-word;
        text-align: center;
    }
    
    ${theme.media.desktop} {
        margin: 14px 0 0;
        font-size: 16px;
    }
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin: -15px 0 15px;
`;

const ToggleQrCodeButton = styled(StyledCheckVoucherButton)`
    margin: 10px 0 0;
    
    ${theme.media.phoneSmall} {
        width: 45%;
        font-size: 13px;
    }
    
    ${theme.media.tabletSmall} {
        font-size: 14px;
        width: 35%;
    }
    
    ${theme.media.tabletLarge} {
        width: 25%;
    }
`;

const StyledQrContainer = styled(QrContainer)`
    margin-bottom: 0px;
`;

const NeedHelpContainer = styled.div`
    display: flex;
    font-family: ${theme.fonts.main};
    margin-bottom: 30px;
`;

const StyledDot = styled.div`
    width: 20px;
    text-align: center;
    font-size: 13px;
    color: ${theme.colors.orange1};
`;

const CustomErrorMessage = styled(StyledErrorMessage)`
    ${theme.media.phoneSmall} {
        font-size: 14px;
        text-align: center;
        margin: 12px 0 0 0;
    }
    
    ${theme.media.desktop} {
        margin: 0;
        text-align: left;
        font-size: 16px;
    }
`;

const VoucherNetworkErrorMessage = styled(ErrorMessage)`
    ${theme.media.phoneSmall} {
        margin: 10px 0 0;
    }
    
    ${theme.media.desktop} {
        margin: 0;
    }
`;

const VoucherMinDepositMessage = styled(VoucherNetworkErrorMessage)`
    color: ${theme.colors.black};
`;

const StyledQrParagraph = styled(QrParagraph)`
    margin: 15px 0 0;
    font-weight: 700;
`;

const CustomInfoMessage = styled(InfoMessage)`
    margin: 0 0 30px;
    font-weight: 500;
`;

const ComplaintText = styled(Paragraph)`
    margin: 0 0 30px;
    font-weight: 500;
    text-align: center;
    
    ${theme.media.phoneSmall} {
        font-size: 13px;
    }
    
    ${theme.media.tabletLarge} {
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        font-size: 15px;
    }
`;

const LastUnderline = styled(StyledUnderline)`
    position: absolute;
    
    ${theme.media.phoneSmall} {
        top: 790px;
        width: 261px;
        left: -14.5px;
    }
    
    ${theme.media.phoneLarge} {
        width: 324px;
        left: -18px;
    }
    
    ${theme.media.tabletSmall} {
        width: 500px;
        left: -50px;
        top: 730px;
    }
    
    ${theme.media.tabletLarge} {
        width: 525px;
        left: 0;
        top: 690px;
    }
    
    ${theme.media.desktop} {
        top: 1000px;
        width: 680px;
    }
`;

const VoucherProviderInfo = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    font-family: ${theme.fonts.main};
    font-size: 12px;
    color: ${theme.colors.grey1};
    padding-bottom: 20px;
    text-align: center;
    font-weight: 300;
    
    ${theme.media.phoneSmall} {
        top: 810px;
        width: 261px;
        left: -14.5px;
    }
    
    ${theme.media.phoneLarge} {
        width: 324px;
        left: -18px;
    }
    
    ${theme.media.tabletSmall} {
        width: 500px;
        left: -50px;
        top: 750px;
    }
    
    ${theme.media.tabletLarge} {
        width: 525px;
        left: 0;
        top: 710px;
    }
    
    ${theme.media.desktop} {
        top: 1020px;
        width: 680px;
    }
`;

const StyledTypography = styled(Typography)`
    &.MuiTypography-root {
        font-family: ${theme.fonts.main};
        text-align: center;
        
        ${theme.media.phoneSmall} {
            font-size: 14px;
        }
        
        ${theme.media.desktop} {
            font-size: 16px;
        }
    }
`;

const StyledBox = styled(Box)`
    &.MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${theme.colors.white};
        border: none;
        border-radius: 5px;
        
        ${theme.media.phoneSmall} {
            width: 260px;
            padding: 15px;
        }
        
        ${theme.media.tabletSmall} {
            width: 400px;
            padding: 20px;
        }
    }
`;

const StyledLabelTerms = styled.label`
    font-family: ${theme.fonts.main};
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    
    ${theme.media.phoneSmall} {
        font-size: 12px;
    }
    
    ${theme.media.phoneLarge} {
        font-size: 13px;
    }
    
    ${theme.media.tabletLarge} {
        font-size: 14px;
    }
`;

const StyledInputTerms = styled.input`
    accent-color: ${theme.colors.orange1};
    margin-right: 10px;
    outline: none;
`;

const TermsDownload = styled.a`
    font-family: ${theme.fonts.main};
    text-decoration: none;
    margin-bottom: 10px;
    
    :hover {
        text-decoration: underline;
        color: ${theme.colors.orange1};
    }
`;

const StyledAnchor = styled.a`
    text-decoration: none;
    
    :hover {
        text-decoration: underline;
    }
`;

const SellCrypto = () => {

    const VOUCHER_DATA_API = 'https://otc.kanga.exchange/api/';
    const VOUCHER_RATES_API = 'https://rates.kanga.services/api/rate/system';
    const VOUCHER_CURRENCIES_API = 'https://otc.kanga.exchange/data/voucher-currencies.json';

    const QRCodeCanvas = require('qrcode.react');
    const saveAs = require('file-saver');

    const myRef = useRef(null);

    const setVoucherNetworkInitialValue = () => {
        if ((typeof window !== "undefined" && !window.location.href.includes("en")) && (typeof window !== "undefined" && !window.location.href.includes("uk"))) {
            return ("Wybierz sieć")
        } else if (typeof window !== "undefined" && window.location.href.includes("en")) {
            return ("Choose network")
        } else if (typeof window !== "undefined" && window.location.href.includes("uk")) {
            return ("Виберіть мережу")
        }
    };

    const [currencies, setCurrencies] = useState(null);
    const [networks, setNetworks] = useState(null);

    const [voucherCurrencies, setVoucherCurrencies] = useState(null);

    const [cryptoCurrencies, setCryptoCurrencies] = useState(null);
    const [minDepositMessage, setMinDepositMessage] = useState("");

    const [generateAddress, setGenerateAddress] = useState(true);
    const [checkVoucher, setCheckVoucher] = useState(false);
    const [voucherAccount, setVoucherAccount] = useState(false);

    const [currentCryptoCurrency, setCurrentCryptoCurrency] = useState("BTC");
    const [chosenCurrentCryptoCurrencyNetwork, setChosenCurrentCryptoCurrencyNetwork] = useState(setVoucherNetworkInitialValue());
    const [chosenNetwork, setChosenNetwork] = useState("");
    const [voucherNetworkErrorMessage, setVoucherNetworkErrorMessage] = useState("");
    const [blockchainAddress, setBlockchainAddress] = useState(null);
    const [confiramtion, setConfirmation] = useState(null);

    const [voucherPin, setVoucherPin] = useState(null);
    const [voucherId, setVoucherId] = useState(null);

    const [voucherAddress, setVoucherAddress] = useState(null);
    const [voucherCurrency, setVoucherCurrency] = useState(null);
    const [voucherNetwork, setVoucherNetwork] = useState(null);
    const [voucherDetails, setVoucherDetails] = useState(false);
    const [generateAddressActive, setGenerateAddressActive] = useState(true);

    const [sellingRate, setSellingRate] = useState(true);
    const [withdrawalATM, setWithdrawalATM] = useState(false);
    const [cantorWithdrawal, setCantorWithdrawal] = useState(false);

    const [cryptoesCourse, setCryptoesCourse] = useState(null);

    const [qrAddressCopied, setQrAddressCopied] = useState(false);

    const [userVoucherId, setUserVoucherId] = useState("");
    const [userVoucherPin, setUserVoucherPin] = useState("");
    const [userVoucherErrorMessage, setUserVoucherErrorMessage] = useState("");

    const [chosenCryptoCurrentCourse, setChosenCryptoCurrentCourse] = useState(null);

    const [sellCryptoValue, setSellCryptoValue] = useState(null);

    const [currentCryptoWalletValues, setCurrentCryptoWalletValues] = useState(null);

    const [sellFailSellMessage, setSellFailSellMessage] = useState(null);

    const [qrCodeVisibility, setQrCodeVisibility] = useState(false);

    const [acceptTerms, setAcceptTerms] = useState(false);

    const [clientIPAddress, setClientIPAddress] = useState('');

    const [selectedCryptoValueForSale, setSelectedCryptoValueForSale] = useState(null)
    const [selectedCryptoCurrencyForSale, setSelectedCryptoCurrencyForSale ] = useState(null)

    const scrollToHowItWorks = () => myRef.current.scrollIntoView();

    const {languages, changeLanguage} = useI18next();

    const [open, setOpen] = React.useState(false);
    const handleOpen = (value, currency) => {
        setSelectedCryptoValueForSale(value)
        setSelectedCryptoCurrencyForSale(currency)
        setOpen(true)
    };
    const handleClose = () => {
    setSelectedCryptoValueForSale(null)
    setSelectedCryptoCurrencyForSale(null)
        setOpen(false)
    };

    const [isMaintenance, setIsMaintenance] = useState(null)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: '5px',
        boxShadow: 10,
        p: 5,
    };

    const handleGenerateAddress = () => {
        setGenerateAddress(true);
        setCheckVoucher(false);
        setVoucherDetails(false);
        setGenerateAddressActive(true);
        setVoucherAccount(false);
        setQrAddressCopied(false);
        setUserVoucherPin("");
        setUserVoucherId("");
        setUserVoucherErrorMessage("");
        setSellCryptoValue(null);
        setVoucherNetworkErrorMessage("");
        setMinDepositMessage("");
        setChosenCurrentCryptoCurrencyNetwork(setVoucherNetworkInitialValue());
        setAcceptTerms(false);
    };

    const handleCheckVoucher = () => {
        setGenerateAddress(false);
        setCheckVoucher(true);
        setVoucherDetails(false);
        setVoucherAccount(false);
        setQrAddressCopied(false);
        setUserVoucherPin("");
        setUserVoucherId("");
        setUserVoucherErrorMessage("");
        setSellCryptoValue(null);
        setVoucherNetworkErrorMessage("");
        setMinDepositMessage("");
        setAcceptTerms(false);
    };

    const chooseVoucherNetwork = (e) => {
        setChosenCurrentCryptoCurrencyNetwork(e.target.textContent);
        setChosenNetwork(e.target.id);


        for (const el of networks) {
            if (!window.location.href.includes("en") && !window.location.href.includes("uk") && e.target.id === el.name) {
                setMinDepositMessage(`Minimalna wpłata: ${el.deposit} ${currentCryptoCurrency}`)
            } else if (window.location.href.includes("en") && e.target.id === el.name) {
                setMinDepositMessage(`Minimum deposit: ${el.deposit} ${currentCryptoCurrency}`)
            } else if (window.location.href.includes("uk") && e.target.id === el.name) {
                setMinDepositMessage(`Мінімальний депозит: ${el.deposit} ${currentCryptoCurrency}`)
            }
        }

        setVoucherNetworkErrorMessage("");
    };

    const signInToVoucher = () => {
        setGenerateAddress(false);
        setCheckVoucher(true);
        setVoucherDetails(false);
    };

    const toggleQrCode = () => {
        setQrCodeVisibility(!qrCodeVisibility);
    };

    const generateVoucherAddress = () => {

        if (chosenCurrentCryptoCurrencyNetwork === "Wybierz sieć" || chosenCurrentCryptoCurrencyNetwork === "Choose network" || chosenCurrentCryptoCurrencyNetwork === "Виберіть мережу") {
            setVoucherNetworkErrorInfo();
        } else {
            fetch(VOUCHER_DATA_API + "voucher", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "command": "generate",
                    "currency": currentCryptoCurrency,
                    "source": "kanga",
                    "ip": clientIPAddress,
                    "network": chosenNetwork
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.result === "ok") {
                        setVoucherPin(data.pin);
                        setVoucherId(data.voucher);

                        fetch(VOUCHER_DATA_API + "voucher", {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "command": "get",
                                "voucher": data.voucher,
                                "pin": data.pin
                            })
                        })

                            .then(response => response.json())
                            .then(data => {
                                if (data.result === "ok") {
                                    setVoucherAddress(data.address);
                                    setVoucherCurrency(data.currency);
                                    setGenerateAddressActive(false);
                                    setVoucherDetails(true);
                                    setVoucherNetwork(data.network);
                                    setBlockchainAddress(data.url);
                                    setConfirmation(data.confirmation);
                                }
                            })

                            .catch(error => console.log(error))
                    }
                })

                .catch(error => console.log(error))
        }
    };

    const onClick = (e) => {
        let currenciesCopy = currencies.slice();

        for (const el of currenciesCopy) {
            if (el.symbol === e.target.id) {
                el.active = true;
                setNetworks(el.networks);
            } else {
                el.active = false;
            }
        }
        setCurrencies(currenciesCopy);

        setChosenCurrentCryptoCurrencyNetwork(setVoucherNetworkInitialValue());
        setCurrentCryptoCurrency(e.target.id);
        setVoucherNetworkErrorMessage("");
        setMinDepositMessage("");
    };

    const setCurrentCurrencies = (callback) => {
        fetch(VOUCHER_CURRENCIES_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(response => response.json())
            .then(data => {
                setVoucherCurrencies(data);
                callback(data);
                for (const el of data) {
                    if (el.name === "Bitcoin") {
                        setNetworks(el.networks);
                    }
                }

                const currenciesWithActive = [];

                data.map(el => {

                    let obj = el;
                    const pair = {active: el.name === "Bitcoin"};
                    obj = {...obj, ...pair};

                    currenciesWithActive.push(obj);

                });

                setCurrencies(currenciesWithActive);
            })

            .catch(error => console.log(error));

    };

    const getExchangeRates = (data) => {
        const cryptoCurrencies = [];
        const cryptoesCourse = [];

        fetch(VOUCHER_RATES_API, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(response => response.json())
            .then(rates => {
                for (const el of data) {
                    cryptoCurrencies.push(el.symbol);
                    rates.exchange.filter(rate => {
                        if (rate.cryptoCurrency === el.symbol && rate.fiatCurrency === 'PLN') {
                            cryptoesCourse.push(rate.sellRate.toFixed(2));
                        }
                    })
                }
                setCryptoCurrencies(cryptoCurrencies);
                setCryptoesCourse(cryptoesCourse);
            })

            .catch(error => console.log(error))
    };

    const getAtmRates = () => {
        const cryptoesCourse = [];
        fetch(VOUCHER_RATES_API, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(response => response.json())
            .then(data => {
                for (const el of cryptoCurrencies) {
                    data.atm.filter(rate => {
                        if (rate.cryptoCurrency === el && rate.fiatCurrency === 'PLN') {
                            cryptoesCourse.push(rate.sellRate.toFixed(2));
                        }
                    })
                }
                setCryptoesCourse(cryptoesCourse);
            })

            .catch(error => console.log(error))
    };

    const getStandardRates = () => {
        const cryptoesCourse = [];
        fetch(VOUCHER_RATES_API, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(response => response.json())
            .then(data => {
                for (const el of cryptoCurrencies) {
                    data.standard.filter(rate => {
                        if (rate.cryptoCurrency === el && rate.fiatCurrency === 'PLN') {
                            cryptoesCourse.push(rate.sellRate.toFixed(2));
                        }
                    })
                }
                setCryptoesCourse(cryptoesCourse);
            })

            .catch(error => console.log(error))
    };

    const handleSellingRate = () => {
        setSellingRate(true);
        setWithdrawalATM(false);
        setCantorWithdrawal(false);
        getExchangeRates(voucherCurrencies);
    };

    const handleWithdrawalATM = () => {
        setSellingRate(false);
        setWithdrawalATM(true);
        setCantorWithdrawal(false);
        getAtmRates();
    };

    const handleCantorWithdrawal = () => {
        setSellingRate(false);
        setWithdrawalATM(false);
        setCantorWithdrawal(true);
        getStandardRates();
    };

    const copyQr = () => {
        setQrAddressCopied(true);
    };

    const getVoucher = () => {
        fetch(VOUCHER_DATA_API + 'voucher/pdf', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "title": "Bon Kanga Exchange",
                "address": voucherAddress,
                "currency": voucherCurrency,
                "id": voucherId,
                "pin": voucherPin,
                "network": voucherNetwork,
                "lang": settingCurrentLng()
            })
        })

            .then(response => response.blob())
            .then(blob => saveAs(blob, 'kanga-voucher-' + voucherId))

            .catch(error => console.log(error))
    };

    const handleUserVoucherId = (e) => {
        setUserVoucherId(e.target.value);
    };

    const handleUserVoucherPin = (e) => {
        setUserVoucherPin(e.target.value);
    };

    const setVoucherNetworkErrorInfo = () => {
        if (!window.location.href.includes("en") && !window.location.href.includes("uk")) {
            setVoucherNetworkErrorMessage("Uzupełnij sieć dla bonu!")
        } else if (window.location.href.includes("en")) {
            setVoucherNetworkErrorMessage("Fill in the appropriate network!")
        } else if (window.location.href.includes("uk")) {
            setVoucherNetworkErrorMessage("Зазнач мережу для ваучера!")
        }
    };

    const setCompleteErrorMessage = () => {
        if (!window.location.href.includes("en") && !window.location.href.includes("uk")) {
            setUserVoucherErrorMessage("Uzupełnij Id oraz PIN bonu!")
        } else if (window.location.href.includes("en")) {
            setUserVoucherErrorMessage("Enter the ID and PIN of the voucher!")
        } else if (window.location.href.includes("uk")) {
            setUserVoucherErrorMessage("Заповни ідентифікатор та PIN -код ваучера!")
        }
    };

    const setExistErrorMessage = () => {
        if (!window.location.href.includes("en") && !window.location.href.includes("uk")) {
            setUserVoucherErrorMessage("Bon o takich danych nie istnieje!")
        } else if (window.location.href.includes("en")) {
            setUserVoucherErrorMessage("Voucher with such data does not exist!")
        } else if (window.location.href.includes("uk")) {
            setUserVoucherErrorMessage("Ваучер з такими даними не існує!")
        }
    };

    const setUsedVoucherErrorMessage = () => {
        if (!window.location.href.includes("en") && !window.location.href.includes("uk")) {
            setUserVoucherErrorMessage("Bon został już wykorzystany!")
        } else if (window.location.href.includes("en")) {
            setUserVoucherErrorMessage("The voucher has already been used!")
        } else if (window.location.href.includes("uk")) {
            setUserVoucherErrorMessage("Ваучер вже використаний!")
        }
    };

    const voucherRefresh = () => {
        const currentCryptoWallet = [];
        const currentCryptoWalletValues = [];

        fetch(VOUCHER_DATA_API + 'voucher', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "command": "get",
                "voucher": userVoucherId,
                "pin": userVoucherPin
            })
        })

            .then(response => response.json())
            .then(data => {
                for (const el of data.wallets) {
                    currentCryptoWallet.push(el);
                    currentCryptoWalletValues.push(el.value);
                }
                setCurrentCryptoWalletValues(currentCryptoWalletValues.reduce((a, b) => a + b));
                setSellCryptoValue(currentCryptoWallet);
            })

            .catch(error => console.log(error));
    };

    const checkVoucherAccount = () => {
        if (userVoucherId === "" || userVoucherPin === "") {
            setCompleteErrorMessage();
        } else {
            fetch(VOUCHER_DATA_API + 'voucher', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "command": "get",
                    "voucher": userVoucherId,
                    "pin": userVoucherPin
                })
            })

                .then(response => response.json())
                .then(data => {
                    if (data.result === "ok") {
                        setUserVoucherErrorMessage("");
                        setVoucherAddress(data.address);
                        setVoucherId(data.voucher);
                        setVoucherPin(userVoucherPin);
                        setVoucherCurrency(data.currency);
                        setVoucherNetwork(data.network);
                        setConfirmation(data.confirmation);
                        setBlockchainAddress(data.url);
                        setGenerateAddress(false);
                        setVoucherDetails(false);
                        setVoucherAccount(true);
                        setCheckVoucher(false);

                        fetch(VOUCHER_DATA_API + 'rates/place', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        })

                            .then(response => response.json())
                            .then(rates => {
                                rates.exchange.filter(rate => {
                                    if (rate.cryptoCurrency === data.currency && rate.fiatCurrency === 'PLN') {
                                        setChosenCryptoCurrentCourse(rate.sellRate);
                                    }
                                })
                            })

                            .catch(error => console.log(error));

                    } else if (data.result === "fail" && data.code === "1001") {
                        setUsedVoucherErrorMessage();
                        setUserVoucherId("");
                        setUserVoucherPin("");
                    } else {
                        setExistErrorMessage();
                        setUserVoucherId("");
                        setUserVoucherPin("");
                    }
                })

                .catch(error => console.log(error));

            voucherRefresh();
        }
    };

    const settingCurrentLng = () => {
        const isBrowser = () => typeof window !== "undefined";
        if (isBrowser() && window.location.href.includes("en")) {
            return "en";
        } else if (isBrowser() && window.location.href.includes("uk")) {
            return "uk";
        } else {
            return "pl";
        }
    };

    const setTransactionFailedMessage = () => {
        if (!window.location.href.includes("en") && !window.location.href.includes("uk")) {
            setSellFailSellMessage("Transakcja sprzedaży nie powiodła się!")
        } else if (window.location.href.includes("en")) {
            setSellFailSellMessage("Transaction failed!")
        } else if (window.location.href.includes("uk")) {
            setSellFailSellMessage("Не вдалося здійснити операцію продажу!")
        }
    };

    const sellCrypto = (value, currency) => {
        setSellFailSellMessage("");
        handleClose();
        fetch(VOUCHER_DATA_API + 'voucher', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "command": "sell",
                "voucher": voucherId,
                "pin": voucherPin,
                "value": value,
                "currency": currency
            })
        })

            .then(response => response.json())
            .then(data => {
                if (data.result === "ok") {
                    setTimeout(() => {
                        voucherRefresh();
                    }, 3000)
                } else {
                    setTransactionFailedMessage();
                }
            })

            .catch(error => console.log(error));
    };

    const handleAcceptTerms = () => {
        setAcceptTerms(!acceptTerms);
    };

    const checkUrls = async () => {
        const [otcUrl, ratesUrl] = await Promise.all([
            checkOTCUrl(),
            checkRatesUrl(),
        ])

        setIsMaintenance(otcUrl !== "pong" || ratesUrl.result !== "ok")
    }

    const getClientIPAddress = async() => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip
          } catch (error) {
            return ''
          }
    };

    useEffect(() => {
        const fetchIPAddress = async () => {
            const ipAddress = await getClientIPAddress()
            setClientIPAddress(ipAddress)
        }

        checkUrls()
        setCurrentCurrencies(getExchangeRates);

        fetchIPAddress()
    }, []);

    return (
        <StyledContent>
            <StyledDropdown>
                <DropdownToggle variant="success" id="dropdown-basic">
                    {settingCurrentLng()}
                </DropdownToggle>

                <DropdownMenu>
                    {languages.map(lng =>
                        <DropdownItem key={lng} onClick={(e) => {
                            e.preventDefault();
                            changeLanguage(lng);
                        }}>{lng}</DropdownItem>
                    )}
                </DropdownMenu>
            </StyledDropdown>

            <a href="/"><KangaLogo src={logo} style={{width: "198px"}}/></a>
            <ManLogo src={manLogo}/>
            <WomenLogo src={womenLogo}/>
            <StyledH1><Trans>Sprzedaj kryptowalutę w kantorze</Trans></StyledH1>
            <NavigationContainer>
                <NavigationContainerElement active={generateAddress} onClick={handleGenerateAddress}><Trans>Generuj
                    adres</Trans></NavigationContainerElement>
                <NavigationContainerElement active={checkVoucher} onClick={handleCheckVoucher}><Trans>Sprawdź
                    bon</Trans></NavigationContainerElement>
                <NavigationContainerElement onClick={scrollToHowItWorks}><Trans>Jak to
                    działa</Trans>?</NavigationContainerElement>
                <StyledLink href="https://kangakantor.pl/" target="_blank"><Trans>Lista kantorów</Trans></StyledLink>
            </NavigationContainer>
            <StyledUnderline/>

            {generateAddress && generateAddressActive ?
                <>
                    <StyledH2><Trans>wybierz kryptowalutę</Trans></StyledH2>
                    <Text><Trans>i wygeneruj dla niej adres</Trans></Text>
                    <CryptoesLogoContainer>

                        {currencies !== null ?
                            currencies.map((el, index) => {
                                return (
                                    <CryptoesLogoSubContainer key={index} id={el.symbol} active={el.active}
                                                              onClick={(e) => onClick(e)}>
                                        <CryptoLogo src={el.active ? (el.icon.split(",")[1]) : (el.icon.split(",")[0])}
                                                    alt="crypto-icon"/>
                                        <CryptoName active={el.active}>{el.name}</CryptoName>
                                    </CryptoesLogoSubContainer>
                                )
                            })
                            : null}

                    </CryptoesLogoContainer>

                    <ChooseNetworkContainer>
                        <ChooseNetworkTitle><Trans>WYBIERZ SIEĆ DLA BONU:</Trans></ChooseNetworkTitle>
                        <CustomDropdown>
                            <CustomDropdownToggle variant="success" id="dropdown-basic">
                                {chosenCurrentCryptoCurrencyNetwork}
                            </CustomDropdownToggle>

                            <CustomDropdownMenu>

                                {networks !== null ?
                                    networks.map(network =>
                                        <CustomDropdownItem key={network.name} id={network.name}
                                                            onClick={(e) => chooseVoucherNetwork(e)}>{network.label}</CustomDropdownItem>
                                    )
                                    :
                                    null
                                }

                            </CustomDropdownMenu>
                        </CustomDropdown>
                    </ChooseNetworkContainer>

                    <VoucherNetworkErrorMessage>{voucherNetworkErrorMessage}</VoucherNetworkErrorMessage>
                    <VoucherMinDepositMessage>{minDepositMessage}</VoucherMinDepositMessage>

                    {isMaintenance
                    ?
                    <tag-maintenance></tag-maintenance>
                    :
                    <tag-good></tag-good>
                    }

                    <GenerateVoucherButton onClick={generateVoucherAddress}
                                           disabled={acceptTerms === false ? true : false}><Trans>Generuj
                        adres</Trans></GenerateVoucherButton>

                    {typeof window !== "undefined" && !window.location.href.includes("en") && !window.location.href.includes("uk") ?
                        <StyledLabelTerms
                            style={{"color": acceptTerms === false ? `${theme.colors.red}` : `${theme.colors.black}`}}>
                            <StyledInputTerms type="checkbox" onChange={handleAcceptTerms}/>
                            Akceptuję&nbsp;<StyledAnchor
                            href="https://wp.kangakantor.pl/wp-content/uploads/2024/02/Regulamin-NOWY-BON-KANTOR-KANGA__-1-2.pdf"
                            download target="_blank"
                            style={{"color": acceptTerms === false ? `${theme.colors.red}` : `${theme.colors.black}`}}>regulamin</StyledAnchor>&nbsp;usługi
                            Bonu
                            Kanga
                        </StyledLabelTerms>
                        :
                        null
                    }

                    { typeof window !== "undefined" && window.location.href.includes("en") ?
                        <StyledLabelTerms
                            style={{"color": acceptTerms === false ? `${theme.colors.red}` : `${theme.colors.black}`}}>
                            <StyledInputTerms type="checkbox" onChange={handleAcceptTerms}/>
                            I accept the&nbsp;<StyledAnchor
                            href="https://wp.kangakantor.pl/wp-content/uploads/2024/02/Regulamin-NOWY-BON-KANTOR-KANGA__-1-2.pdf"
                            download target="_blank"
                            style={{"color": acceptTerms === false ? `${theme.colors.red}` : `${theme.colors.black}`}}>terms</StyledAnchor>&nbsp;of
                            the Kanga Voucher service
                        </StyledLabelTerms>
                        :
                        null
                    }

                    {typeof window !== "undefined" && window.location.href.includes("uk") ?
                        <StyledLabelTerms
                            style={{"color": acceptTerms === false ? `${theme.colors.red}` : `${theme.colors.black}`}}>
                            <StyledInputTerms type="checkbox" onChange={handleAcceptTerms}/>
                            Я приймаю&nbsp;<StyledAnchor
                            href="https://wp.kangakantor.pl/wp-content/uploads/2024/02/Regulamin-NOWY-BON-KANTOR-KANGA__-1-2.pdf"
                            download target="_blank"
                            style={{"color": acceptTerms === false ? `${theme.colors.red}` : `${theme.colors.black}`}}>умови</StyledAnchor>&nbsp;послуги
                            Bonu Kanga
                        </StyledLabelTerms>
                        :
                        null
                    }

                </>
                :
                null
            }

            {voucherDetails ?
                <>
                    {!window.location.href.includes("en") && !window.location.href.includes("uk") ?
                        <QrAddressContainerPl qrAddress={qrAddressCopied}>
                            <CopyToClipboard text={voucherAddress} onCopy={copyQr}>
                                <QrAddressInput readOnly value={voucherAddress}/>
                            </CopyToClipboard>
                        </QrAddressContainerPl>
                        :
                        null
                    }
                    {window.location.href.includes("en") ?
                        <QrAddressContainerEn qrAddress={qrAddressCopied}>
                            <CopyToClipboard text={voucherAddress} onCopy={copyQr}>
                                <QrAddressInput readOnly value={voucherAddress}/>
                            </CopyToClipboard>
                        </QrAddressContainerEn>
                        :
                        null
                    }
                    {window.location.href.includes("uk") ?
                        <QrAddressContainerUk qrAddress={qrAddressCopied}>
                            <CopyToClipboard text={voucherAddress} onCopy={copyQr}>
                                <QrAddressInput readOnly value={voucherAddress}/>
                            </CopyToClipboard>
                        </QrAddressContainerUk>
                        :
                        null
                    }

                    <VoucherMinDepositMessage>{minDepositMessage}</VoucherMinDepositMessage>
                    <GenerateAddressAlertContainer>
                        <Alert src={redAlert} alt="Alert"/>
                        <AlertText><Trans>Nie księgujemy depozytów nadanych przez kontrakty!</Trans></AlertText>
                    </GenerateAddressAlertContainer>
                    <VoucherRecoveryContainer>
                        <Alert src={redAlert} alt="Alert"/>
                        <AlertText><Trans>Uwaga! Odzyskanie tokenów nieobsługiwanych przez system bonowy - jest
                            niemożliwe. Prosimy o uważny wybór właściwej kryptowaluty w miejscu
                            nadania.</Trans></AlertText>
                    </VoucherRecoveryContainer>
                    <StyledQrParagraph><Trans>Sieć</Trans>: <CustomSpan>{voucherNetwork}</CustomSpan></StyledQrParagraph>
                    <QrContainer>
                        <QRCodeCanvas value={voucherAddress}/>
                    </QrContainer>
                    <CustomH3><Trans>Zapamiętaj poniższe identyfikatory płatności, zanim wykonasz
                        przelew!</Trans></CustomH3>
                    <QrParagraph><Trans>Kryptowaluta</Trans>: <CustomSpan> {voucherCurrency}</CustomSpan></QrParagraph>
                    <QrParagraph><Trans>Identyfikator bonu:</Trans> <CustomVoucherSpan> {voucherId}</CustomVoucherSpan></QrParagraph>
                    <QrParagraph>PIN: <CustomVoucherSpan> {voucherPin}</CustomVoucherSpan></QrParagraph>
                    <GetVoucherButton onClick={getVoucher}><Trans>Pobierz bon</Trans></GetVoucherButton>
                    <ComplaintText><Trans>Podstawą do reklamacji jest pobrany PDF z danymi bonu!</Trans></ComplaintText>
                </>
                :
                null
            }

            {checkVoucher ?
                <>
                    <CheckVoucherTitle><Trans>Zaloguj się do bonu podając id i pin</Trans></CheckVoucherTitle>
                    <CheckVoucherContainer>
                        <StyledInput placeholder="Id" value={userVoucherId}
                                     onChange={handleUserVoucherId}/>
                        <Input placeholder="PIN" value={userVoucherPin} onChange={handleUserVoucherPin}/>
                    </CheckVoucherContainer>
                    <ErrorMessage>{userVoucherErrorMessage}</ErrorMessage>
                    <CheckVoucherButton onClick={checkVoucherAccount}><Trans>Sprawdź stan konta
                        bonu</Trans></CheckVoucherButton>
                </>
                :
                null
            }

            {voucherAccount ?
                <>
                    <ManageVoucherTitle><Trans>Zarządzaj swoim bonem</Trans></ManageVoucherTitle>
                    <VoucherInfoTitle><Trans>Sprawdź stan konta bonu</Trans>:</VoucherInfoTitle>
                    <VoucherInfo>{voucherId}</VoucherInfo>
                    <VoucherInfoTitle><Trans>Adres wpłaty</Trans> {voucherCurrency}:</VoucherInfoTitle>

                    {!window.location.href.includes("en") && !window.location.href.includes("uk") ?
                        <StyledQrAddressContainerPl qrAddress={qrAddressCopied}>
                            <CopyToClipboard text={voucherAddress} onCopy={copyQr}>
                                <QrAddressInput readOnly value={voucherAddress}/>
                            </CopyToClipboard>
                        </StyledQrAddressContainerPl>
                        :
                        null
                    }
                    {window.location.href.includes("en") ?
                        <StyledQrAddressContainerEn qrAddress={qrAddressCopied}>
                            <CopyToClipboard text={voucherAddress} onCopy={copyQr}>
                                <QrAddressInput readOnly value={voucherAddress}/>
                            </CopyToClipboard>
                        </StyledQrAddressContainerEn>
                        :
                        null
                    }
                    {window.location.href.includes("uk") ?
                        <StyledQrAddressContainerUk qrAddress={qrAddressCopied}>
                            <CopyToClipboard text={voucherAddress} onCopy={copyQr}>
                                <QrAddressInput readOnly value={voucherAddress}/>
                            </CopyToClipboard>
                        </StyledQrAddressContainerUk>
                        :
                        null
                    }

                    <AlertContainer>
                        <Alert src={redAlert} alt="Alert"/>
                        <AlertText><Trans>Nie księgujemy depozytów nadanych przez kontrakty!</Trans></AlertText>
                    </AlertContainer>
                    <VoucherRecovery>
                        <Alert src={redAlert} alt="Alert"/>
                        <AlertText><Trans>Uwaga! Odzyskanie tokenów nieobsługiwanych przez system bonowy - jest
                            niemożliwe. Prosimy o uważny wybór właściwej kryptowaluty w miejscu
                            nadania.</Trans></AlertText>
                    </VoucherRecovery>

                    {qrCodeVisibility ?
                        <ToggleQrCodeButton onClick={toggleQrCode}><Trans>Ukryj kod QR</Trans></ToggleQrCodeButton>
                        :
                        <ToggleQrCodeButton onClick={toggleQrCode}><Trans>Pokaż kod QR</Trans></ToggleQrCodeButton>
                    }
                    {qrCodeVisibility ?
                        <StyledQrContainer>
                            <QRCodeCanvas value={voucherAddress}/>
                        </StyledQrContainer>
                        :
                        null}

                    <VoucherInfoTitle><Trans>Transakcje realizowane są wyłącznie w sieci</Trans>:</VoucherInfoTitle>
                    <VoucherInfo>{voucherNetwork}</VoucherInfo>
                    <VoucherInfoTitle><Trans>Aktualny kurs sprzedaży</Trans> {voucherCurrency}:</VoucherInfoTitle>
                    <VoucherInfo>{chosenCryptoCurrentCourse} zł</VoucherInfo>
                    <ParagraphInfo><Trans>Do widocznego kursu sprzedaży zostanie doliczona prowizja kantoru (średnio
                        3%). Masz możliwość negocjacji wysokości prowizji w kantorze. Listę punktów kantorowych
                        znajdziesz na </Trans><HowItWorksLink href="https://kangakantor.pl/"
                                                              target="_blank">kangakantor.pl</HowItWorksLink></ParagraphInfo>
                    <StyledParagraphInfo><Trans>UWAGA! Wypłać w bankomacie do 4000 zł bez
                        KYC.</Trans></StyledParagraphInfo>
                    <StyledParagraphInfo><Trans>Więcej szczegółów pod numerem: </Trans><HowItWorksLink
                        href="tel:+48585733337"
                        target="_blank">+48 58 573 33 37</HowItWorksLink> <Trans>lub na telegramie</Trans>
                        <HowItWorksLink href="https://t.me/KangaBankomaty/"
                                        target="_blank"><Trans>@KangaBankomaty</Trans></HowItWorksLink></StyledParagraphInfo>

                    {!window.location.href.includes("en") && !window.location.href.includes("uk") ?
                        <VoucherInfoContainer>
                            {currentCryptoWalletValues !== 0 && currentCryptoWalletValues !== null && sellCryptoValue !== null ?
                                sellCryptoValue.map(el => {
                                    return (
                                        (cryptoCurrencies.includes(el.currencyCode) || el.currencyCode.includes("o"))  &&
                                        <InputsContainerPl key={el.currencyCode}
                                                           className={el.value === 0 ? "nonActive" : "active"}>
                                            <VoucherValueInput
                                                readOnly={true}
                                                value={el.value + " " + el.currencyCode}
                                            />
                                            {el.currencyCode.charAt(0) === "o" ?
                                                <VoucherInfoButtonDisabled
                                                    id={el.value}><Trans>sprzedaj</Trans></VoucherInfoButtonDisabled>
                                                :
                                                <>
                                                    <VoucherInfoButton id={el.value}
                                                                       onClick={() => handleOpen(el.value, el.currencyCode)}><Trans>sprzedaj</Trans></VoucherInfoButton>
                                                    <Modal
                                                        open={open}
                                                        onClose={handleClose}
                                                    >
                                                        <StyledBox>
                                                            <StyledTypography id="modal-modal-description">
                                                                <Trans>Potwierdzam chęć sprzedaży kryptowaluty i
                                                                    rozumiem, że
                                                                    operacja jest nieodwracalna.</Trans>
                                                            </StyledTypography>
                                                            <ModalButtonsContainer>
                                                                <CancelButton
                                                                    onClick={() => handleClose()}><Trans>Anuluj</Trans></CancelButton>
                                                                <ConfirmButton
                                                                    onClick={() => sellCrypto(JSON.stringify(selectedCryptoValueForSale), selectedCryptoCurrencyForSale)}><Trans>Potwierdzam</Trans></ConfirmButton>
                                                            </ModalButtonsContainer>
                                                        </StyledBox>
                                                    </Modal>
                                                </>
                                            }
                                        </InputsContainerPl>
                                    )
                                })
                                :
                                <>
                                    <EmptyVoucherAlertContainer>
                                        <Alert src={orangeAlert} alt="Alert"/>
                                        <CustomErrorMessage><Trans>Twój bon jest pusty. Załaduj go używając powyższego
                                            adresu</Trans></CustomErrorMessage>
                                    </EmptyVoucherAlertContainer>
                                    <StyledAlertContainer>
                                        <AlertSubContainer>
                                            <Alert src={redAlert} alt="Alert"/>
                                            <StyledAlertText><Trans>Zweryfikuj bon w sieci
                                                blockchain:</Trans></StyledAlertText>
                                        </AlertSubContainer>
                                        <BlockchainAnchor
                                            href={blockchainAddress}
                                            target="_blank">{blockchainAddress}</BlockchainAnchor>
                                        <CustomAlertText><Trans>Wymagana ilość potwierdzeń do zaksięgowania: </Trans>
                                            <AlertTextSpan> {confiramtion}</AlertTextSpan></CustomAlertText>
                                    </StyledAlertContainer>
                                </>
                            }
                        </VoucherInfoContainer>
                        :
                        null
                    }
                    {window.location.href.includes("en") ?
                        <VoucherInfoContainer>
                            {currentCryptoWalletValues !== 0 && currentCryptoWalletValues !== null && sellCryptoValue !== null ?
                                sellCryptoValue.map(el => {
                                    return (
                                        (cryptoCurrencies.includes(el.currencyCode) || el.currencyCode.includes("o"))  &&
                                        <InputsContainerEn key={el.currencyCode}
                                                           className={el.value === 0 ? "nonActive" : "active"}>
                                            <VoucherValueInput
                                                readOnly={true}
                                                value={el.value + " " + el.currencyCode}
                                            />
                                            {el.currencyCode.charAt(0) === "o" ?
                                                <VoucherInfoButtonDisabled
                                                    id={el.value}><Trans>sprzedaj</Trans></VoucherInfoButtonDisabled>
                                                :
                                                <>
                                                    <VoucherInfoButton id={el.value}
                                                                       onClick={() => handleOpen(el.value, el.currencyCode)}><Trans>sprzedaj</Trans></VoucherInfoButton>
                                                    <Modal
                                                        open={open}
                                                        onClose={handleClose}
                                                    >
                                                        <StyledBox sx={style}>
                                                            <StyledTypography id="modal-modal-description">
                                                                <Trans>Potwierdzam chęć sprzedaży kryptowaluty i
                                                                    rozumiem, że
                                                                    operacja jest nieodwracalna.</Trans>
                                                            </StyledTypography>
                                                            <ModalButtonsContainer>
                                                                <CancelButton
                                                                    onClick={() => handleClose()}><Trans>Anuluj</Trans></CancelButton>
                                                                <ConfirmButton
                                                                    onClick={() => sellCrypto(JSON.stringify(selectedCryptoValueForSale), selectedCryptoCurrencyForSale)}><Trans>Potwierdzam</Trans></ConfirmButton>
                                                            </ModalButtonsContainer>
                                                        </StyledBox>
                                                    </Modal>
                                                </>
                                            }
                                        </InputsContainerEn>
                                    )
                                })
                                :
                                <>
                                    <EmptyVoucherAlertContainer>
                                        <Alert src={orangeAlert} alt="Alert"/>
                                        <CustomErrorMessage><Trans>Twój bon jest pusty. Załaduj go używając powyższego
                                            adresu</Trans></CustomErrorMessage>
                                    </EmptyVoucherAlertContainer>
                                    <StyledAlertContainer>
                                        <AlertSubContainer>
                                            <Alert src={redAlert} alt="Alert"/>
                                            <StyledAlertText><Trans>Zweryfikuj bon w sieci
                                                blockchain:</Trans></StyledAlertText>
                                        </AlertSubContainer>
                                        <BlockchainAnchor
                                            href={blockchainAddress}
                                            target="_blank">{blockchainAddress}</BlockchainAnchor>
                                        <CustomAlertText><Trans>Wymagana ilość potwierdzeń do zaksięgowania: </Trans>
                                            <AlertTextSpan>{confiramtion}</AlertTextSpan></CustomAlertText>
                                    </StyledAlertContainer>
                                </>
                            }
                        </VoucherInfoContainer>
                        :
                        null
                    }
                    {window.location.href.includes("uk") ?
                        <VoucherInfoContainer>
                            {currentCryptoWalletValues !== 0 && currentCryptoWalletValues !== null && sellCryptoValue !== null ?
                                sellCryptoValue.map(el => {
                                    return (
                                        (cryptoCurrencies.includes(el.currencyCode) || el.currencyCode.includes("o"))  &&
                                        <InputsContainerUk key={el.currencyCode}
                                                           className={el.value === 0 ? "nonActive" : "active"}>
                                            <VoucherValueInput
                                                readOnly={true}
                                                value={el.value + " " + el.currencyCode}
                                            />
                                            {el.currencyCode.charAt(0) === "o" ?
                                                <VoucherInfoButtonDisabled
                                                    id={el.value}><Trans>sprzedaj</Trans></VoucherInfoButtonDisabled>
                                                :
                                                <>
                                                    <VoucherInfoButton id={el.value}
                                                                       onClick={() => handleOpen(el.value, el.currencyCode)}><Trans>sprzedaj</Trans></VoucherInfoButton>
                                                    <Modal
                                                        open={open}
                                                        onClose={handleClose}
                                                    >
                                                        <StyledBox sx={style}>
                                                            <StyledTypography id="modal-modal-description">
                                                                <Trans>Potwierdzam chęć sprzedaży kryptowaluty i
                                                                    rozumiem, że
                                                                    operacja jest nieodwracalna.</Trans>
                                                            </StyledTypography>
                                                            <ModalButtonsContainer>
                                                                <CancelButton
                                                                    onClick={() => handleClose()}><Trans>Anuluj</Trans></CancelButton>
                                                                <ConfirmButton
                                                                    onClick={() => sellCrypto(JSON.stringify(selectedCryptoValueForSale), selectedCryptoCurrencyForSale)}><Trans>Potwierdzam</Trans></ConfirmButton>
                                                            </ModalButtonsContainer>
                                                        </StyledBox>
                                                    </Modal>
                                                </>
                                            }
                                        </InputsContainerUk>
                                    )
                                })
                                :
                                <>
                                    <EmptyVoucherAlertContainer>
                                        <Alert src={orangeAlert} alt="Alert"/>
                                        <CustomErrorMessage><Trans>Twój bon jest pusty. Załaduj go używając powyższego
                                            adresu</Trans></CustomErrorMessage>
                                    </EmptyVoucherAlertContainer>
                                    <StyledAlertContainer>
                                        <AlertSubContainer>
                                            <Alert src={redAlert} alt="Alert"/>
                                            <StyledAlertText><Trans>Zweryfikuj bon w sieci
                                                blockchain:</Trans></StyledAlertText>
                                        </AlertSubContainer>
                                        <BlockchainAnchor
                                            href={blockchainAddress}
                                            target="_blank">{blockchainAddress}</BlockchainAnchor>
                                        <CustomAlertText><Trans>Wymagana ilość potwierdzeń do zaksięgowania: </Trans>
                                            <AlertTextSpan>{confiramtion}</AlertTextSpan></CustomAlertText>
                                    </StyledAlertContainer>
                                </>
                            }
                        </VoucherInfoContainer>
                        :
                        null
                    }
                    <StyledErrorMessage>{sellFailSellMessage}</StyledErrorMessage>
                    <StyledCheckVoucherButton onClick={voucherRefresh}><Trans>Odśwież stan
                        bonu</Trans></StyledCheckVoucherButton>
                </>
                :
                null
            }

            <StyledUnderline/>
            <RatesButtonsContainer>
                <RatesButton active={sellingRate} onClick={handleSellingRate}><Trans>Kurs
                    sprzedaży</Trans></RatesButton>
                <RatesButton active={withdrawalATM} onClick={handleWithdrawalATM}><Trans>Wypłata w
                    bankomacie</Trans></RatesButton>
                <RatesButton active={cantorWithdrawal} onClick={handleCantorWithdrawal}><Trans>Wypłata w
                    kantorze</Trans></RatesButton>
            </RatesButtonsContainer>
            <CryptoesContainer>
                {cryptoCurrencies !== null ?
                    cryptoCurrencies.map(crypto => {
                        return (
                            <ContainerElement key={crypto}>{crypto}</ContainerElement>
                        )
                    })
                    :
                    null
                }
            </CryptoesContainer>
            <CryptoesCourseContainer>
                {cryptoesCourse !== null ?
                    cryptoesCourse.map((course, index) => {
                        return (
                            <ContainerElement key={index}>{course} zł</ContainerElement>
                        )
                    })
                    :
                    null
                }
            </CryptoesCourseContainer>

            {sellingRate ?
                <>
                    <InfoMessage><Trans>W każdej chwili możesz wymienić środki z bonu na stablecoin PLN° po widocznym
                        kursie sprzedaży.</Trans></InfoMessage>
                    <LoginMessage onClick={signInToVoucher}><Trans>Możesz to zrobić po zalogowaniu się do bonu.</Trans></LoginMessage>
                </>
                :
                null
            }
            {withdrawalATM ?
                <>
                    <InfoMessage><Trans>Wypłać w bankomacie do 4000 zł bez KYC.</Trans></InfoMessage>
                    <LastInfoMessage><Trans>Więcej szczegółów pod numerem:</Trans> <HowItWorksLink
                        href="tel:+48585733336"
                        target="_blank">+48 58 573 33 36</HowItWorksLink>
                        <Trans>lub na telegramie</Trans>
                        <HowItWorksLink href="https://t.me/KangaBankomaty/"
                                        target="_blank"><Trans>@KangaBankomaty</Trans></HowItWorksLink></LastInfoMessage>
                </>
                :
                null
            }
            {cantorWithdrawal ?
                <>
                    <InfoMessage><Trans>Wysokość kursu przy 3% prowizji kantoru.</Trans></InfoMessage>
                    <StyledInfoMessage><Trans>Uwaga! Kantor może pobrać dowolną prowizję za wypłatę. Masz możliwość
                        negocjacji wysokości prowizji w kantorze.</Trans></StyledInfoMessage>
                    <LastInfoMessage><Trans>Listę punktów kantorowych znajdziesz na </Trans><HowItWorksLink
                        href="https://kangakantor.pl/" target="_blank">kangakantor.pl</HowItWorksLink></LastInfoMessage>
                </>
                :
                null
            }

            <InfoMessage><Trans>Masz pytania? Potrzebujesz pomocy?</Trans></InfoMessage>
            <NeedHelpContainer>
                <HowItWorksLink href="tel:+48585733337" target="_blank">+48 58 573 33 37</HowItWorksLink>
                <StyledDot>•</StyledDot> <HowItWorksLink href="https://t.me/KantorKanga/"
                                                         target="_blank"><Trans>@KantorKanga</Trans></HowItWorksLink>
            </NeedHelpContainer>
            <CustomInfoMessage><Trans>Kontakt telefoniczny i obsługa Telegramu jest wyłącznie w języku polskim oraz
                angielskim.</Trans></CustomInfoMessage>
            <StyledUnderline/>
            <CustomH2 ref={myRef}><Trans>Jak to działa</Trans></CustomH2>
            <HowItWorksMainContainer>
                <HowItWorksSubContainer/>
                <HowItWorksChooseCryptoContainer>
                    <StyledH3>1. <Trans>Wybierz walutę</Trans></StyledH3>
                    <StyledParagraph><Trans>Wybierz walutę, którą chcesz sprzedać. Pamiętaj, że kurs wyświetlany jest w
                        czasie rzeczywistym - środki, które otrzymasz na skutek wymiany zostana naliczone w chwili
                        zamknięcia transakcji w punkcie stacjonarnym i na skutek różnic kursowych kwota ta może różnić
                        się
                        od prognozowanej.</Trans></StyledParagraph>
                </HowItWorksChooseCryptoContainer>
                <HowItWorksGenerateAddressContainer>
                    <StyledH3>2. <Trans>Wygeneruj adres</Trans></StyledH3>
                    <StyledParagraph><Trans>Kliknij w buton GENERUJ ADRES - to adres, na który wpłacisz kryptowalutę do
                        wymiany.</Trans></StyledParagraph>
                </HowItWorksGenerateAddressContainer>
                <HowItWorksPaymentDetailsContainer>
                    <StyledH3>3. <Trans>Zapamiętaj dane</Trans></StyledH3>
                    <StyledParagraph><Trans>WAŻNE! Pamiętaj, aby zapisać identyfikator płatności i PIN, które zobaczysz
                        przy adresie do wypłaty. Bez nich nie będziesz mógł dokończyć transakcji w punkcie
                        stacjonarnym.</Trans></StyledParagraph>
                </HowItWorksPaymentDetailsContainer>
                <HowItWorksPayInContainer>
                    <StyledH3>4. <Trans>Wpłać środki</Trans></StyledH3>
                    <StyledParagraph><Trans>Na wygenerowany adres wpłać kryptowalutę w ilości, którą chcesz
                        wymienić.</Trans></StyledParagraph>
                </HowItWorksPayInContainer>
                <HowItWorksExchangeOfficeContainer>
                    <StyledH3>5. <Trans>Idź do kantoru</Trans></StyledH3>
                    <StyledParagraph><Trans>Odwiedź wybrany kantor stacjonarny - listę kantorów znajdziesz na
                        stronie </Trans><HowItWorksLink href="https://kangakantor.pl/"
                                                        target="_blank">kangakantor.pl</HowItWorksLink></StyledParagraph>
                </HowItWorksExchangeOfficeContainer>
                <HowItWorksTransactionContainer>
                    <StyledH3>6. <Trans>Dokonaj transakcji</Trans></StyledH3>
                    <StyledParagraph><Trans>Poinformuj pracownika kantoru, że chcesz wymienić kryptowalutę i podaj mu
                        identyfikator płatności. WAŻNE! Nie podawaj kasjerowi PINu zanim nie potwierdzi, że wymiana może
                        być
                        zrealizowana!</Trans></StyledParagraph>
                </HowItWorksTransactionContainer>
                <HowItWorksCashContainer>
                    <StyledH3>7. <Trans>Odbierz gotówkę!</Trans></StyledH3>
                    <LastParagraph><Trans>Jeśli pracownik kantoru potwierdzi, że może dokonać wymiany, podaj mu PIN i
                        odbierz gotówkę.</Trans></LastParagraph>
                </HowItWorksCashContainer>
                <LastUnderline/>
                <VoucherProviderInfo>
                    <p><TermsDownload
                        href="https://wp.kangakantor.pl/wp-content/uploads/2024/02/Regulamin-NOWY-BON-KANTOR-KANGA__-1-2.pdf" download
                        target="_blank"><Trans>Regulamin Bon Kanga</Trans></TermsDownload></p>
                    <Trans>Usługę Bonu dostarcza Good Solution Investments Limited, Cromwell House, Ground-Floor, 117
                        Albert Street, Belize City, Belize - Operator</Trans>
                </VoucherProviderInfo>
            </HowItWorksMainContainer>
        </StyledContent>
    )
};

export default SellCrypto;

// export const query = graphql`
//   query($language: String!) {
//     locales: allLocale(filter: {language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;
