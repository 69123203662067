import styled from "styled-components";
import {theme} from "../../utils/theme";

export const H3 = styled.h3`
    font-family: ${theme.fonts.main};
    font-weight: 400;
    
    ${theme.media.phoneSmall}{
        font-size: 13px;
    }
    
    ${theme.media.tabletLarge} {
       font-size: 16px;
    }
    
    ${theme.media.desktop} {
        font-size: 18px;
    }
`;
