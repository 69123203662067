import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body, html {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  
  *, *::before, *::after {
    box-sizing: border-box
  }
`;
