import styled from "styled-components";
import {theme} from "../../utils/theme";

export const Paragraph = styled.p`
    font-family: ${theme.fonts.main};
    font-weight: 300;
    
    ${theme.media.phoneSmall} {
        font-size: 12px;
    }
    
    ${theme.media.phoneLarge}{
        font-size: 13px;
    }

    ${theme.media.desktop} {
        font-size: 14px;
    }
`;
