import React from 'react';
import {GlobalStyle} from '../components/UsableComponents/GlobalStyle';
import SellCrypto from '../components/SellCrypto/SellCrypto';
import { Helmet } from "react-helmet";
import {graphql} from "gatsby";

const IndexPage = () => {

    return (
        <>
            <Helmet>
                <title>Sprzedaj kryptowalutę w kantorze - Bon Kanga Kantor</title>
                <meta name="description" content="Sprzedaj kryptowalutę w kantorze bez zakładania konta na giełdzie Kanga. Wybierz kryptowalutę i wygeneruj dla niej adres. Wejdź i dowiedz się więcej!" />
            </Helmet>
            <GlobalStyle/>
            <SellCrypto/>
        </>
    )

};

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
