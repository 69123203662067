export default async function checkRatesUrl() {
    const url = "https://rates.kanga.services/api/ping"

    const response = await fetch( url, {
      method: `GET`,
      headers: {
        "Content-Type": `application/json`,
      },
    } )
      .then( res => res.json() )
      .catch( err => err )

    return response
  }