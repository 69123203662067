export default async function checkOTCUrl() {
    const url = `https://otc.kanga.exchange/api/ping`

    const response = await fetch( url, {
      method: `GET`,
      headers: {
        "Content-Type": `application/json`,
      },
    } )
      .then( res => res.text() )
      .catch( err => err )

    return response
  }