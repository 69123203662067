import styled from "styled-components";
import {theme} from "../../utils/theme";

export const Input = styled.input`
    width: 150px;
    height: 35px;
    font-family: ${theme.fonts.main};
    border: 2px solid ${theme.colors.orange1};
    text-align: right;
    padding-right: 10px;
    border-radius: 5px;
    
    &:focus {
        outline: none;
    }
`;
